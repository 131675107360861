import { defineStore } from 'pinia'
import {useCookieStore} from "~/store/cookie";

export const useAudioSegmentStore = defineStore({
    id: 'audio-segment-store',
    state: () => {
        return {
          segment: null,
          fetchError: false,
          segmentsPage: null,
          notFoundError: false,
          deleteError: false,
        }
    },
    actions: {
        async createSegment(key, start, end, transcribe, language_id, number_of_speakers, name, config) {
            const cookieStore = useCookieStore();

            const resp = await $fetch(config.public.externalApiBase +`/api/segment/create/`, {
                method: 'POST',
                server: false,
                headers: { 'Authorization': 'Token ' + cookieStore.token},
                body: {
                    audio_file_upload: key,
                    original_start: start,
                    original_end: end,
                    transcribe: transcribe,
                    transcription_language: language_id,
                    number_of_speakers: number_of_speakers,
                    name: name
                }
            }).catch((error) => {
                this.fetchError = true;
                console.log(error);
            })

            this.segment = resp;
        },
        async getSegments(page, config) {
          let params = {
            page: page
          }

          const cookieStore = useCookieStore();
          const resp = await $fetch(config.public.externalApiBase + `/api/segments/`, {
            method: 'GET',
            headers: {'Authorization': 'Token ' + cookieStore.token},
            params: params,
          }).catch((error) => {
            this.fetchError = true;
          });

          if (resp) {
            this.segmentsPage = resp;
          }
        },
      async getSegment(key, config) {
          const cookieStore = useCookieStore();
          const resp = await $fetch(config.public.externalApiBase + `/api/segments/${key}/`, {
            method: 'GET',
            headers: {'Authorization': 'Token ' + cookieStore.token},
          }).catch((error) => {
            if (error.response.status === 404) {
              this.notFoundError = true;
            }
            else {
              this.fetchError = true;
            }
          });

          if (resp) {
            this.segment = resp;
          }
      },
      async updateSegment(key, name, config) {
        const cookieStore = useCookieStore();
        const resp = await $fetch(config.public.externalApiBase + `/api/segments/${key}/`, {
          method: 'PATCH',
          headers: {'Authorization': 'Token ' + cookieStore.token},
          body: {name: name}
        }).catch((error) => {
          console.log(error.response);
          this.fetchError = true;
        });
      },
      async deleteSegment(key, config) {
        const cookieStore = useCookieStore();
        const resp = await $fetch(config.public.externalApiBase + `/api/segments/${key}/`, {
            method: 'DELETE',
            headers: {'Authorization': 'Token ' + cookieStore.token},
        }).catch((error) => {
            console.log(error.response)
            if (error.response.status === 404) {
                this.notFoundError = true;
            }
            else {
                this.deleteError = true;
            }
        });

        if (resp) {
            this.segment = null;
        }
      },
      async getPresignedUrl(key, config) {
           const cookieStore = useCookieStore();
            const {data, error} = await useFetch(config.public.externalApiBase +`/api/segments/${key}/presigned_url/`, {
                method: 'GET',
                headers: { 'Authorization': 'Token ' + cookieStore.token}
            }).catch((error) => {
                this.fetchError = true;
            });

            if (data.value) {
              this.fetchError = false;
              this.notFoundError = false;
              return data.value.url;
            }

            if (error.value) {
              if (error.value.statusCode === 404) {
                this.notFoundError = true;
              }
              else {
                this.fetchError = true;
              }
            }
        },
    }

});